import { useState } from "react";
import {Heading, HStack, Image, Text, VStack, Box, background} from "@chakra-ui/react";
import { RadioGroup, RadioOption } from "./Radio";
import { ProgressCircle } from "./Progress";
import { Info } from "./Info";
import './Vragenlijst.css';

export default function Vragenlijst() {

    enum Score {
        nooit = 0,
        soms = 1,
        vaak = 2,
        altijd = 3
    }

    interface VragenlijstType {
        naam?: string,
        leeftijd?: number,
        vragen: VraagType[],
        huidigeVraag: number
    }

    interface VraagType {
        id?: string, // URI
        vraagstelling: string,
        toelichting?: string,
        antwoord?: Score
    }

    const vragenlijst: VragenlijstType = {
        vragen: [
            { vraagstelling: "Ik let onvoldoende op details bij mijn werk.", toelichting: "Dit vragen we, want..." },
            { vraagstelling: "Wanneer ik zit, friemel ik met mijn handen of voeten." },
            { vraagstelling: "Ik maak slordige fouten in mijn werk." },
            { vraagstelling: "Ik zit te wiebelen en te draaien in mijn stoel." },
            { vraagstelling: "Wanneer ik met iets bezig ben, kan ik er met mijn aandacht slecht bij blijven." },
            { vraagstelling: "Ik sta snel op van mijn stoel in situaties waarin verwacht wordt dat ik netjes blijf zitten." },
            { vraagstelling: "Ik luister slecht wanneer anderen iets tegen mij zeggen." },
            { vraagstelling: "Ik voel me rusteloos." },
            { vraagstelling: "Ik verveel me snel." },
            { vraagstelling: "Ik heb moeite aanwijzingen op te volgen." },
            { vraagstelling: "Karweitjes of werk waar ik aan begin, maak ik niet af." },
            { vraagstelling: "Ik kan me moeilijk ontspannen in mijn vrije tijd." },
            { vraagstelling: "In mijn vakantie of vrije tijd zoek ik een omgeving met drukte en lawaai." },
            { vraagstelling: "Ik kan mijn bezigheden of taken moeilijk organiseren." },
            { vraagstelling: "Ik ben voortdurend ‘in de weer’, alsof ik ‘door een motor word aangedreven’." },
            { vraagstelling: "Ik probeer onder bezigheden uit te komen waarop ik me langere tijd moet concentreren." },
            { vraagstelling: "Ik praat aan één stuk door." },
            { vraagstelling: "Ik raak dingen kwijt die ik nodig heb voor taken of bezigheden." },
            { vraagstelling: "Ik geef antwoord voordat vragen zijn afgemaakt." },
            { vraagstelling: "Ik ben snel afgeleid." },
            { vraagstelling: "Ik vind het moeilijk op mijn beurt te wachten." },
            { vraagstelling: "Ik ben vergeetachtig bij alledaagse bezigheden." },
            { vraagstelling: "Ik onderbreek anderen of val ze in de rede." },
        ],
        huidigeVraag: 0
    }

    const [vragenlijstState, setVragenlijstState] = useState(vragenlijst)
    const huidigeVraag: VraagType = vragenlijstState.vragen[vragenlijstState.huidigeVraag]
    const aantalVragen = vragenlijstState.vragen.length
    const progress = () => {
        const beantwoord = vragenlijstState.vragen.filter(vraag => vraag.antwoord).length
        return beantwoord * 100 / aantalVragen;
    }

    const [toonResultaten, setToonResultaten] = useState(false)

    const geefAntwoord = (antwoord: Score) => {
        setVragenlijstState(prev => {
            let newState = { ...prev,
                vragen: [...prev.vragen],
                huidigeVraag: Math.min(prev.huidigeVraag + 1, prev.vragen.length - 1)
            }
            console.log("antwoord: " + antwoord + " , score: " + Score[antwoord])
            newState.vragen[prev.huidigeVraag].antwoord = antwoord
            return newState
        })
    }

    const vorigeVraag = () => {
        setVragenlijstState((prev)  => {
            return { ...prev,
                huidigeVraag: prev.huidigeVraag - 1
            }
        })
    }

    const volgendeVraag = () => {
        if (vragenlijstState.huidigeVraag !== vragenlijstState.vragen.length - 1) {
            setVragenlijstState((prev)  => {
                return { ...prev,
                    huidigeVraag: prev.huidigeVraag + 1
                }
            })
        } else {
            setToonResultaten(true)
        }
    }

    if (!toonResultaten) {
        return (
            <VStack align="center">
                <div className="vraagstelling">{huidigeVraag.vraagstelling}</div>
                <Info size={100}>{huidigeVraag.toelichting}</Info>
                <RadioGroup onChange={(antwoord: Score) => geefAntwoord(antwoord)} selected={huidigeVraag.antwoord}>
                    <RadioOption value="nooit">Nooit of zelden</RadioOption>
                    <RadioOption value="soms">Soms</RadioOption>
                    <RadioOption value="vaak">Vaak</RadioOption>
                    <RadioOption value="altijd">Erg vaak</RadioOption>
                </RadioGroup>
                <ProgressCircle percentage={progress()} size={200}/>
                <HStack>
                    <button disabled={(vragenlijstState.huidigeVraag === 0)} onClick={vorigeVraag}>Vorige vraag</button>
                    <button disabled={!huidigeVraag.antwoord}
                            onClick={volgendeVraag}>{vragenlijstState.huidigeVraag < aantalVragen - 1 ? "Volgende vraag" : "Resultaat"}</button>
                </HStack>
            </VStack>
        )
    } else {
        const totaalScore = vragenlijstState.vragen.reduce((sum, next) => sum + +Score[next.antwoord!], 0)
        const v1a = (+Score[vragenlijstState.vragen[0].antwoord!] >= 2 || +Score[vragenlijstState.vragen[2].antwoord!] >= 2) ? 1 : 0
        const v1b = (+Score[vragenlijstState.vragen[4].antwoord!] >= 2) ? 1 : 0
        const v1c = (+Score[vragenlijstState.vragen[6].antwoord!] >= 2) ? 1 : 0
        const v1d = (+Score[vragenlijstState.vragen[9].antwoord!] >= 2 || +Score[vragenlijstState.vragen[10].antwoord!] >= 2) ? 1 : 0
        const v1e = (+Score[vragenlijstState.vragen[13].antwoord!] >= 2) ? 1 : 0
        const v1f = (+Score[vragenlijstState.vragen[15].antwoord!] >= 2) ? 1 : 0
        const v1g = (+Score[vragenlijstState.vragen[17].antwoord!] >= 2) ? 1 : 0
        const v1h = (+Score[vragenlijstState.vragen[19].antwoord!] >= 2) ? 1 : 0
        const v1i = (+Score[vragenlijstState.vragen[21].antwoord!] >= 2) ? 1 : 0
        const algemeen = v1a + v1b + v1c + v1d + v1e + v1f + v1g + v1h + v1i
        const v2a = (+Score[vragenlijstState.vragen[1].antwoord!] >= 2 || +Score[vragenlijstState.vragen[3].antwoord!] >= 2) ? 1 : 0
        const v2b = (+Score[vragenlijstState.vragen[5].antwoord!] >= 2) ? 1 : 0
        const v2c = (+Score[vragenlijstState.vragen[7].antwoord!] >= 2 || +Score[vragenlijstState.vragen[8].antwoord!] >= 2) ? 1 : 0
        const v2d = (+Score[vragenlijstState.vragen[11].antwoord!] >= 2 || +Score[vragenlijstState.vragen[12].antwoord!] >= 2) ? 1 : 0
        const v2e = (+Score[vragenlijstState.vragen[14].antwoord!] >= 2) ? 1 : 0
        const v2f = (+Score[vragenlijstState.vragen[16].antwoord!] >= 2) ? 1 : 0
        const v2g = (+Score[vragenlijstState.vragen[18].antwoord!] >= 2) ? 1 : 0
        const v2h = (+Score[vragenlijstState.vragen[20].antwoord!] >= 2) ? 1 : 0
        const v2i = (+Score[vragenlijstState.vragen[22].antwoord!] >= 2) ? 1 : 0
        const hyper = v2a + v2b + v2c + v2d + v2e + v2f + v2g + v2h + v2i
        const a = algemeen >= 4 ? "Ja" : "Nee"
        const h = hyper >= 4 ? "Ja" : "Nee"
        return (
            <div className="resultaten">
                {vragenlijstState.vragen.map((vraag, index) => { return (
                    <div key={index}>
                        <span><strong>{+Score[vraag.antwoord!]}</strong>&nbsp;{vraag.vraagstelling}</span>
                    </div>
                )})}
                <span>Totaal score: <strong>{totaalScore}</strong></span><br/>
                A: {algemeen} / 9 - Is het aantal A kenmerken &gt;= 4? <strong>{a}</strong><br/>
                H/I: {hyper} / 9 - Is het aantal H/I kenmerken &gt;= 4? <strong>{h}</strong><br/>
                <button onClick={() => setToonResultaten(false)}>Terug naar vragen</button>
            </div>
        )
    }
}

