import * as React from "react";
import "./styles.css";

export const RadioGroup = ({ onChange, selected, children }) => {
  const RadioOptions = React.Children.map(children, ((child, index) => {
      return React.cloneElement(child, {
          onChange,
          checked: child.props.value === selected
      });
  }));

  return <div className="RadioGroup">{RadioOptions}</div>;
};

export const RadioOption = ({ value, checked = false, onChange = () => {}, children }) => {
    const className = "RadioOption" + (checked ? " selected" : "");
    return (
        <span className={className} id={value} onClick={(e) => onChange(value)}>
            {children}
        </span>
    );
};
