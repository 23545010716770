import { useState } from "react";
import "./styles.css";

export const Info = ({size, children}: {size: number, children?: string}) => {
    const [opened, setOpened] = useState(false)
    if (!opened) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 200 200" className="info">
                <circle cx="100" cy="100" r="100" fill="#0000ff" onClick={() => setOpened(true)}/>
                <text x="100" y="120" fontSize="200"
                      fontFamily="serif"
                      alignmentBaseline="middle"
                      textAnchor="middle"
                      pointerEvents="none"
                      fill="white">
                    i
                </text>
            </svg>
        )
    } else {
        return (
            <div onClick={() => setOpened(false)} className="info">{children}</div>
        )
    }
}
