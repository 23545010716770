import "./styles.css";

export const ProgressCircle = ( {percentage, size} ) => {
    const completeness = percentage * 625 / 100;
    return (
        <svg width={size} height={size} className="progress" viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-290 410) rotate(270 50 50)">
                <circle cx="400" cy="400" fill="none"
                        r="100" strokeWidth="2" stroke="#000000"
                />
                <circle cx="400" cy="400" fill="none"
                        r="100" strokeWidth="20" stroke="#0000ff"
                        strokeDasharray={completeness + " 1000"}
                        strokeLinecap="round"
                />
            </g>
        </svg>
    )
}
